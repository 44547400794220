<template>
  <product-by-vendor-report />
</template>

<script>
import ProductByVendorReport from '@/components/pages/product/ProductByVendorReport'

export default {
  components: {
    ProductByVendorReport
  }
}
</script>
